<template>
  <div :class="[role]" class="flex-col ai-chat" v-loading="!ready">
    <!-- 非选择模板界面时 -->
    <template v-if="templateIndex === -1">
      <!-- 固定的头部 -->
      <div class="header flex-between" style="z-index: 2">
        <div class="header-left">
          <el-button @click="oldConversations" type="primary"
            ><img
              :src="require('@/modules/ai/assets/history.png')"
              class="btn__icon"
            />
            <span class="btn__text">历史对话</span></el-button
          >
          <el-button
            :loading="newLoading"
            @click="beforeCreateNewConversation()"
            type="primary"
            ><img
              :src="require('@/modules/ai/assets/new.png')"
              class="btn__icon"
            /><span class="btn__text">新对话</span></el-button
          >
          <!-- 感觉滚动到底实际用起来有时候不好用，留着测试 -->
<!--          <el-button @click="toLatestPosition">test</el-button>-->
        </div>
        <div class="header-right">
          <!--          <el-button plain title="TODO: 点数">点数：{{ tokens }}</el-button>-->
        </div>
      </div>

      <el-scrollbar class="ai-chat-scroll flex-1">
        <div class="ai-chat__inner flex-col flex-1" id="ai">
          <!-- 历史记录 -->
          <div
            @mouseleave="hoverI = -1"
            class="float-card flex-col"
            v-if="showHistory"
            v-loading="delLoading || !sessionList.list.length"
          >
            <div class="float-header">历史记录</div>
            <el-scrollbar v-loadmore="getSessionList">
              <div class="scroll-content flex-col">
                <el-checkbox-group v-model="idList">
                  <template v-for="(session, sessionI) in sessionList.list">
                    <!--
v-if="session_id !== session.id"
-->
                    <div
                      :class="[showCheckbox ? 'check-mode' : '']"
                      :key="session.id"
                      @click="useSession(session.id)"
                      @mouseenter="hoverI = sessionI"
                      class="history-item"
                    >
                      <el-checkbox
                        :label="session.id"
                        :disabled="session.id === session_id"
                        class="full-check flex-align-center"
                        v-if="showCheckbox"
                      >
                        <span></span>
                      </el-checkbox>
                      <div
                        :style="{
                          color: session.id === session_id ? '#3a7aff' : '',
                        }"
                        class="title"
                      >
                        {{ session.title }}
                      </div>
                      <div class="subtitle">
                        {{ session.update_time || '还未发起过对话' }}
                      </div>
                      <el-button-group
                        @click.stop
                        class="del-btn flex-align-center"
                        v-if="hoverI === sessionI"
                      >
                        <el-button
                          @click.stop="checkSome(session.id)"
                          type="text"
                          v-if="!showCheckbox"
                          >批量删除</el-button
                        >
                        <el-button
                          @click.stop="exitCheckMore"
                          type="text"
                          v-else
                          >退出批量删除</el-button
                        >
                        <el-divider direction="vertical"></el-divider>
                        <el-button
                          @click.stop="delSession(session.id)"
                          style="color: red"
                          type="text"
                          >删除</el-button
                        >
                      </el-button-group>
                    </div>
                  </template>
                </el-checkbox-group>
              </div>
            </el-scrollbar>
            <div class="float-footer flex-between flex-align-center">
              <div>
                <el-checkbox v-if="showCheckbox" v-model="checkAll"
                  >全选</el-checkbox
                ><span class="el-checkbox__label" v-if="idList.length > 0"
                  >已选 {{ idList.length }} 条</span
                >
              </div>
              <div>
                <el-button @click="showHistory = false" plain>关闭</el-button>
                <el-button @click="delMore" type="danger" v-if="showCheckbox"
                  >删除</el-button
                >
              </div>
            </div>
          </div>

          <!-- 欢迎界面，提问示例 -->
          <div class="row" v-if="!myTemplate && !dialogs.length && !waiting">
            <div class="flex-center">
              <div class="welcome">
                <!--                <img-->
                <!--                  :src="require('@/modules/ai/assets/welcome.png')"-->
                <!--                  class="wh-full"-->
                <!--                />-->
                <div class="welcome-ball"></div>
                <div class="welcome-shadow"><div class="__shadow"></div></div>
              </div>
            </div>
            <div class="suggestions">
              <div class="intro">
                <img
                  :src="require('@/modules/ai/assets/idea.png')"
                  class="idea"
                />{{ '已为您找到询问模板...' }}
              </div>
              <div class="card">
                <p class="p">{{ '提示词推荐：' }}</p>
                <div class="list flex flex-col">
                  <div
                    :key="s"
                    @click="question += s"
                    class="sentence cursor-pointer"
                    v-for="s in suggestions"
                  >
                    <img
                      :src="require('@/modules/ai/assets/suggestion.png')"
                      class="i"
                    />
                    <p class="p">{{ s }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 已选模板时，展示模板名称和模板内容 -->
          <div v-else-if="myTemplate">
            <div
              :class="[
                myTemplate.root ? myTemplate.root.key : '',
                fullMyTemplate ? 'full' : '',
              ]"
              class="my-template"
              @click="fullMyTemplate = !fullMyTemplate"
            >
              <div v-if="myTemplate.root && myTemplate.root.key">
                <img
                  :src="
                    require(`@/modules/ai/assets/keywords/${myTemplate.root.key}.png`)
                  "
                  class="my-template-icon"
                />
              </div>
              <div class="flex-col">
                <div class="my-template-title">{{ myTemplate.text }}</div>
                <div class="my-template-desc">{{ myTemplate.description || myTemplate.value }}</div>
              </div>
            </div>
          </div>

          <!--        {{ getVisible() }}=>{{ autoFocus }}-->
          <!-- 对话上下文 -->
          <!--  :key="JSON.stringify(d)"写法导致每次更新都触发 created)  -->

          <div
            :key="d.id"
            class="row"
            style="padding-top: 12px; padding-bottom: 0"
            v-for="(d, dI) in dialogs"
          >
            <!--        {{ d.role }}: {{ d.content }}-->
            <template v-if="d.role === 'user'">
              <div class="dialog-item">
                <a-i-chat-item
                  :is-last="dI === dialogs.length - 1"
                  :me="d.role === 'user'"
                  @edit="onEditQuestion($event, dI)"
                  v-bind="d"
                />
              </div>
              <div class="dialog-item" v-if="d.children">
                <a-i-chat-item
                  :is-last="dI === dialogs.length - 1"
                  :me="d.children.role === 'user'"
                  :pid="d.id"
                  :versions="d.versions || undefined"
                  @repeat="onRepeatQuestion(dI)"
                  v-bind="d.children"
                />
              </div>
            </template>
            <div class="dialog-item" v-else-if="d.role === 'assistant'">
              <a-i-chat-item
                :is-last="dI === dialogs.length - 1"
                @repeat="onRepeatQuestion(dI)"
                v-bind="d"
                :content="d.content || '正在生成中...'"
              />
            </div>
            <!-- 考虑还有其他，如：系统提示，仅前端使用，不要提交给后端 -->
            <div
              class="dialog-item"
              v-else-if="d.role === 'system' && d.is_usage !== 1"
            >
              <a-i-chat-item v-bind="d" />
            </div>
          </div>

          <div
            id="latest"
            :style="{
              opacity:
                !(myTemplate && !dialogs.length) &&
                !(getLastDialog() && getLastDialog().role === 'assistant')
                  ? 1
                  : 0,
            }"
            style="min-height: 120px; transform: scale(0.8)"
            v-loading="waiting"
            class="row"
          ></div>

          <!-- 自适应 -->
          <div class="flex-1"></div>
        </div>
      </el-scrollbar>
      <div class="footer flex-col">
        <!-- 自适应 -->
        <div class="flex-1"></div>
        <!-- 快捷功能 -->
        <el-scrollbar
          style="width: 100%; height: 66px; overflow-y: hidden"
          v-if="!myTemplate"
        >
          <div class="shortcut-list flex">
            <div
              :class="[s.key]"
              :key="s.key"
              @click="clickTemplateChoice(sI)"
              class="shortcut-item cursor-pointer flex-align-center"
              v-for="(s, sI) in templateList"
            >
              <img
                :src="require(`@/modules/ai/assets/${s.key}.png`)"
                class="icon"
              />
              <span class="span">{{ s.name }}</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </el-scrollbar>
        <!-- 模板模式 -->
        <el-scrollbar
          style="width: 100%; height: 46px; overflow-y: hidden"
          v-if="myTemplate && myTemplate.keywords && myTemplate.keywords.length"
        >
          <div class="footer-keywords flex">
            <div
              :key="k"
              @click="useKeyword(k)"
              class="keyword cursor-pointer flex-align-center"
              v-for="(k, kI) in myTemplate.keywords"
            >
              <span class="_text">{{ k }}：</span>
            </div>
          </div>
        </el-scrollbar>
        <!--      {{focusRow}}-->
        <!-- 输入区域 -->
        <div class="input-box flex">
          <div
            :class="{ less: lessThan, focusing: focusRow !== 1 }"
            class="input-wrapper flex-1"
          >
            <div class="input-wrapper-inner" style="width: 100%">
              <!--
            @blur="focusRow = 1"
            :autosize="{ minRows: focusRow + 5, maxRows: 24 }"
            -->
              <el-input
                :autosize="{minRows: focusRow, maxRows: 5}"
                resize="none"
                :rows="focusRow"
                :placeholder="inputPlaceholder"
                @focus="onResize"
                @keydown.native="handleEnterAdd($event)"
                autofocus
                :style="{
                  'min-height': `calc(${focusRow} * 1.5em)`,
                  'max-height': maxInputHeight || '60vh',
                }"
                ref="textarea"
                type="textarea"
                v-model="question"
              />
              <!--
v-focus="autoFocus"
 @keydown.native="handleEnterAdd($event)"
-->
              <div
                class="el-textarea__inner"
                ref="questionRef"
                style="white-space: pre-wrap; position: fixed; left: 200%"
              >
                {{ question }}
              </div>
            </div>
          </div>

          <div
            @click="sendQuestion()"
            class="send flex-center cursor-pointer"
            title="Enter 换行；Alt + Enter 发送；"
            :class="{ gray: waiting }"
          >
            <!--            <i class="el-icon-top"></i>-->
            <img
              :src="require('@/modules/ai/assets/send-btn.png')"
              class="wh-full"
            />
          </div>
        </div>
      </div>
    </template>
    <div class="template-screen flex-col ai-chat" v-else>
      <div class="bg flex">
        <img
          :src="
            require(`@/modules/ai/assets/${templateList[templateIndex].key}_bg.png`)
          "
          class="w-full"
        />
      </div>
      <div
        :class="templateList[templateIndex].key"
        class="flex-col flex-1 template-content"
      >
        <el-scrollbar>
          <div class="template-header">
            <div class="header-left"></div>
            <div class="header-right">
              <div class="title">{{ templateList[templateIndex].name }}</div>
              <div class="desc">
                {{ templateList[templateIndex].description }}
              </div>
            </div>
          </div>
          <div class="template-subtitle">
            <div class="subtitle">相关模板</div>
          </div>
          <el-checkbox-group @change="onSubChange" v-model="subTemplateIndex">
            <div class="template-grid">
              <div
                :class="{ checked: subTemplateIndex.includes(t.key) }"
                :key="t.key"
                class="template-grid-item flex-col"
                v-for="t in templateList[templateIndex].templates"
              >
                <img
                  :src="
                    require(`@/modules/ai/assets/keywords/${templateList[templateIndex].key}.png`)
                  "
                  class="grid-icon"
                />
                <div class="grid-title">{{ t.text }}</div>
                <div class="grid-desc">{{ t.description || t.value }}</div>
                <el-checkbox :label="t.key" class="checkbox"
                  ><span></span
                ></el-checkbox>
              </div>
            </div>
          </el-checkbox-group>
        </el-scrollbar>
      </div>
      <div class="footer flex-between">
        <div class="footer-left"></div>
        <div class="footer-right">
          <el-button @click="closeTemplate()">取消</el-button>
          <el-button @click="useTemplate()" type="primary">确定</el-button>
        </div>
        <div v-if="role !== 'dialog'"><!-- 使居中 --></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ai_chat_list,
  ai_create_chat,
  ai_create_session,
  ai_del_session,
  ai_save_usage,
  ai_session_list,
  ai_template_list,
} from '../api/ai-chat'
import fetchStream from '../../../base/utils/fetchStream'
import AIChatItem from './AIChatItem'

import throttle from 'lodash/throttle'

import dayjs from 'dayjs'
// 启用中文
require('dayjs/locale/zh-cn')
dayjs.locale('zh-cn')

// 相对时间
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)
const relativeTimeConfig = {
  future: '%s内',
  past: '%s',
  s: '刚刚',
  m: '1 分钟前',
  mm: '%d 分钟前',
  h: '1 小时前',
  hh: '%d 小时前',
  d: '1 天前',
  dd: '%d 天前',
  M: '1 个月前',
  MM: '%d 个月前',
  y: '1 年前',
  yy: '%d 年前',
}
dayjs.updateLocale('zh-cn', {
  relativeTime: relativeTimeConfig,
})

export default {
  name: 'ai-chat',
  components: { AIChatItem },
  inject: {
    getVisible: {
      default: () => false,
    },
  },
  props: {
    role: {
      type: String,
      default: 'dialog', // dialog | xxx
    },
  },
  data() {
    return {
      ready: false,
      newLoading: false,
      suggestions: [
        '帮我策划一个主题为《东莞茂商会课程之商学院六月课程 - 低成本运作品牌》的活动。',
        '帮我写一份活动简介',
      ],
      inputPlaceholder: '有问题尽管问我...',
      question: '',
      // shortcuts: [
      //   {
      //     name: '文章编辑',
      //   },
      //   {
      //     name: '活动策划',
      //   },
      //   {
      //     name: '日常运营',
      //   },
      // ],
      myTemplate: null,

      lessThan: true, // 提问少于 n 行时

      dialogs: [],

      waiting: false,

      autoFocus: false,
      focusRow: 1,
      maxInputHeight: '60vh',

      showHistory: false,
      sessionList: {
        list: [],
        page_size: 15,
        page: 1,
        loading: true, // false 表示没有数据了
      },
      hoverI: -1,
      delLoading: false,
      showCheckbox: false,
      checkAll: false,
      idList: [],

      templateList: [],
      templateIndex: -1, // 正在打开哪个模板
      subTemplateIndex: [],
      fullMyTemplate: false,

      tokens: 0, // 4096tokens
    }
  },
  computed: {
    visible() {
      return this.getVisible()
    },
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.ready = false
        setTimeout(() => {
          this.autoFocus = true
        }, 500)
        this.getChatList()
      } else {
        this.autoFocus = newValue
        this.ready = true
      }
    },
    checkAll(val) {
      this.idList = []
      if (val) {
        this.sessionList.list.forEach((li) => {
          if (li.id !== this.session_id) {
            this.idList.push(li.id)
          }
        })
      }
    },
    focusRow(val) {
      if (val === 1) {
        this.maxInputHeight = '0'
        this.inputPlaceholder = '有问题尽管问我...'
      } else {
        this.maxInputHeight = '60vh'
        this.inputPlaceholder = '提示：按 Alt + Enter 换行；按 Enter 发送；'
      }
    },
  },
  mounted() {
    // const local_session_id = localStorage.getItem('ai_session_id')
    // if (local_session_id) this.session_id = local_session_id;

    this.getSessionList()

    this.getTemplates()
  },
  methods: {
    abortAIAnswer() {
      // 不管 fetchStream 是否已经响应，都进入中断状态
      this.aborting = true // 等待真正取消

      if (this.controller) {
        this.waiting = true
        this.controller.abortRequest() // 仍需等待真正取消
        this.controller = null
      }
    },
    beforeCreateNewConversation() {
      this.abortAIAnswer()

      this.repeatI = -1
      this.templateIndex = -1
      this.subTemplateIndex = []
      this.myTemplate = null // 点击左上角的新对话才清空
      this.session_id = ''

      this.newConversation()
    },
    clickTemplateChoice(sI) {
      if (this.dialogs.length) {
        this.$confirm('是否立即开始新的对话？', '提示', {})
          .then(() => {
            this.newConversation()
            this.templateIndex = sI
          })
          .catch(() => {})
      } else {
        this.templateIndex = sI
      }
    },
    useKeyword(keyword) {
      if (this.question) this.question += `\n${keyword}：`
      else this.question = keyword + '：'
    },
    async useTemplate() {
      if (!this.subTemplateIndex.length) return

      const sub_key = this.subTemplateIndex[0]
      this.myTemplate = {
        ...this.templateList[this.templateIndex].templates.find(
          (t) => t.key === sub_key
        ),
        root: this.templateList[this.templateIndex],
      }

      // if (this.question) this.question += `\n${this.myTemplate.value}：`
      // else this.question = this.myTemplate.value + '：'

      this.templateIndex = -1
      this.subTemplateIndex = []

      this.waiting = true

      // 创建一次我的提问
      const { data } = await ai_create_chat({
        // {
        //   "session_id":"",//会话id
        //     "role_type":0,//0：用户提问，1：ai回答，2：模板背景
        //     "content":"",
        //     "is_same_problem":0//用户是否将问题修改后再提问
        // }
        session_id: this.session_id,
        role_type: 2,
        content: this.myTemplate.value,
        is_same_problem: 0,
      })

      if (data) {
        this.chat_id = data.chat_id
        this.getChatList(false)
      }
    },
    onSubChange(e) {
      // console.log(e) // ['outline']
      if (e.length > 1) this.subTemplateIndex.splice(0, 1)
    },
    getTemplates() {
      ai_template_list()
        .then(({ data, msg }) => {
          this.templateList = data.list
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onRepeatQuestion(i) {
      // 重复问题，再问一次，得到多个答案版本
      this.repeatI = i
      if (this.getLastDialog().role === 'assistant') {
        // 这个不是问题
        this.dialogs.splice(this.dialogs.length - 1, 1)
        this.repeatI--
      } else if (this.getLastDialog().role === 'user') {
        this.getLastDialog().children = null
      }
      console.log('重新问这个问题：', this.getLastDialog().content)
      this.isEdit = false // 问题没改，重新提问
      this.sendQuestion(this.getLastDialog().content)
    },
    onEditQuestion(content, i) {
      this.question = content
      this.repeatI = i // 重复提问问题所在的对话下标 i
      this.isEdit = true
    },
    exitCheckMore() {
      this.showCheckbox = false
      this.idList = []
    },
    delMore() {
      if (this.idList.includes(this.session_id)) {
        this.$message.error('当前对话不可删除')
        return
      }

      this.delLoading = true
      if (!this.idList.length) return

      Promise.all(
        this.idList.map(
          (id) =>
            new Promise((resolve) => {
              ai_del_session({
                session_id: id,
              }).finally(() => {
                resolve()
              })
            })
        )
      )
        .then(() => {
          this.idList = []
          this.checkAll = false
          this.sessionList.page = 1
          this.sessionList.list = []
          this.sessionList.loading = true
          this.getSessionList()
          this.delLoading = false
          this.showCheckbox = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    closeTemplate() {
      this.templateIndex = -1
      this.subTemplateIndex = []
    },
    checkSome(id) {
      this.idList = []

      if (id === this.session_id) {
      } else this.idList.push(id)
      this.showCheckbox = true
    },
    delSession(id) {
      if (id === this.session_id) {
        this.$message.error('当前对话不可删除')
        return
      }

      this.delLoading = true

      ai_del_session({
        session_id: id,
      })
        .then(() => {
          const sessionI = this.sessionList.list.findIndex((s) => s.id === id)
          this.sessionList.list.splice(sessionI, 1)
          this.delLoading = false
        })
        .catch(() => {
          this.delLoading = false
        })
    },
    getSessionList() {
      if (this.sessionList.loading === false) {
        return
      }

      ai_session_list({
        page_size: this.sessionList.page_size,
        page: this.sessionList.page,
      })
        .then(({ data, msg }) => {
          if (!this.session_id) {
            // this.session_id = data.data[0].id
            // this.getChatList()
            this.newConversation()
          } else {
            if (!this.ready) this.ready = true
          }

          if (data.data.length) {
            this.sessionList.list.push(...data.data)
            this.sessionList.page++
          } else {
            this.sessionList.loading = false
          }
        })
        .catch(() => {})
    },
    // 失去焦点就调保存接口
    blurAddFun() {
      if (this.question) {
        console.log(this.question, '调取保存接口')
        this.sendQuestion()
      }
    },
    // 需求是按了enter保存,同时按下alt + enter键换行
    handleEnterAdd(event) {
      // 保存功能
      // event.altKey: 是否按了alt键。event.keyCode === 13: 按了enter键
      if (!event.altKey && event.keyCode === 13) {
        // 失去焦点且内容不为空会触发保存接口
        // this.$refs.textarea.blur()
        this.sendQuestion()
        // 阻止浏览器默认换行操作
        event.preventDefault()
        return false
      }

      // 换行功能
      // event.altKey: 是否按了alt键。event.keyCode === 13: 按了enter键
      if (event.altKey && event.keyCode === 13) {
        // 获取光标位置
        const cursorPosition = event.target.selectionStart
        // 在光标位置插入换行
        this.question =
          this.question.substring(0, cursorPosition) +
          '\n' +
          this.question.substring(cursorPosition)
        this.$nextTick(() => {
          // 解决换行光标跑到整段文本后面
          this.$refs.textarea.$el
            .querySelector('textarea')
            .setSelectionRange(cursorPosition + 1, cursorPosition + 1)
        })
        // this.sendQuestion()
        // 阻止浏览器默认换行操作
        event.preventDefault()
        return false
      }

      // event.keyCode === 32: 按下空格
      // 中文输入法下按下空格或其他的键: event.keyCode === 229
      if (event.keyCode === 32 || event.keyCode === 229) {
        // 阻止冒泡,解决在编辑文本框的时候按空格textarea不显示空格但播放视频的bug
        event.stopPropagation()
      }
    },
    useSession(id) {
      if (this.showCheckbox) return

      this.myTemplate = null
      this.session_id = id
      this.showHistory = false
      this.dialogs = []
      this.waiting = true
      this.getChatList(false) // 参数：不需要显示到最底部
    },
    oldConversations() {
      this.showHistory = !this.showHistory
      this.checkAll = false
      this.showCheckbox = false
      this.hoverI = -1
      this.delLoading = false
      this.idList = []

      this.sessionList.page = 1
      this.sessionList.list = []
      this.sessionList.loading = true
      this.getSessionList()
    },
    newConversation() {
      this.ready = false
      this.dialogs = []
      this.createSession()
    },
    getDialogs() {
      return [
        ...this.dialogs,
        {
          role: 'user', // only assistant or user
          content: this.question,
        },
      ]
    },
    getLastDialog() {
      return this.dialogs[this.dialogs.length - 1]
    },
    toLatestPosition: throttle(
      function () {
        this.$nextTick(() => {
          setTimeout(() => {
            let end = document.getElementById('latest')
            end.scrollIntoView({ block: 'center' })
          }, 300)
        })
      },
      50,
      { leading: true }
    ),
    async sendQuestion(question = '') {
      if (this.aborting) {
        this.repeatI = -1
        this.aborting = false
        return
      }

      if (this.waiting) {
        this.repeatI = -1
        return
      }

      const myQuestion = question || this.question

      if (!myQuestion) {
        this.$message.error('请输入问题')
        this.repeatI = -1
        return
      }

      // UI更新
      if (this.repeatI >= 0 && this.isEdit) {
        // 修改问题后，重新提问
        this.dialogs.splice(this.repeatI, 1, {
          role: 'user',
          content: myQuestion,
          time: dayjs(Date.now()).fromNow(),
          children: null,
        })
        this.repeatI = -1
      } else if (this.repeatI === -1 || this.repeatI === undefined) {
        this.dialogs.push({
          role: 'user',
          content: myQuestion,
          time: dayjs(Date.now()).fromNow(),
        })
      }

      this.waiting = true // 等待回答中
      this.question = '' // 清空输入框的值

      // AI 开始回答了
      this.dialogs.push({
        role: 'assistant',
        content: '',
        time: dayjs(new Date().getTime()).fromNow(),
      })

      this.toLatestPosition() // 到达最新消息的位置

      try {
        if (this.aborting) {
          this.repeatI = -1
          this.aborting = false
          return
        }

        if (!this.session_id) {
          // this.$message.success('对话初始化未完成，请稍后再试')
          // return
          await this.createSession()
        }

        if (this.aborting) {
          this.repeatI = -1
          this.aborting = false
          return
        }

        // 创建一次我的提问
        const { data } = await ai_create_chat({
          // {
          //   "session_id":"",//会话id
          //     "role_type":0,//0：用户提问，1：ai回答，2：模板背景
          //     "content":"",
          //     "is_same_problem":0//用户是否将问题修改后再提问
          // }
          session_id: this.session_id,
          role_type: 0,
          content: myQuestion,
          is_same_problem: this.repeatI >= 0 ? 1 : 0,
        })

        if (this.aborting) {
          this.repeatI = -1
          this.aborting = false
          return
        }

        if (data) {
          // 先保存起来
          this.chat_id = data.chat_id

          // UI更新（挪到上面去了）
          // if (this.repeatI >= 0 && this.isEdit) {
          //   // 修改问题后，重新提问
          //   this.dialogs.splice(this.repeatI, 1, {
          //     role: 'user',
          //     content: myQuestion,
          //     time: dayjs(Date.now()).fromNow(),
          //     children: null,
          //   })
          //   this.repeatI = -1
          // } else if (this.repeatI === -1 || this.repeatI === undefined) {
          //   this.dialogs.push({
          //     role: 'user',
          //     content: myQuestion,
          //     time: dayjs(Date.now()).fromNow(),
          //   })
          // }

          // 询问 AI
          this.sendAI()
        } else {
          // 可能出错了
          // UI更新
          if (this.repeatI >= 0 && this.isEdit) {
            // 修改问题后，重新提问
            this.dialogs.push({
              role: 'system',
              content: '出错了，请稍后尝试',
              time: dayjs(new Date().getTime()).fromNow(),
            })
            this.repeatI = -1
          } else if (this.repeatI === -1 || this.repeatI === undefined) {
            this.dialogs.push({
              role: 'system',
              content: '出错了，请稍后尝试',
              time: dayjs(new Date().getTime()).fromNow(),
            })
          }

          this.repeatI = -1
        }
      } catch (e) {
        console.log(e)
        this.waiting = false
      }
    },
    sendAI() {
      if (this.aborting) {
        this.repeatI = -1
        this.aborting = false
        return
      }

      // 询问 AI
      fetchStream({
        url: '/admin/ai/chat/send',
        method: 'post',
        credentials: 'same-origin', // include, *same-origin, omit
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        data: {
          session_id: this.session_id,
        },
        // 读取流，TODO：名字改成 onmessage 好点？结构和后端的一致：{code,data,msg}
        callback: async (responseData) => {
          // if (this.waiting) {
          //   if (this.getLastDialog().role === 'assistant') {
          //     this.getLastDialog().content = ''
          //   }
          // }

          if (this.aborting) {
            this.repeatI = -1
            this.aborting = false
            this.abortAIAnswer()
            return
          }

          const { code, data, msg } = responseData

          this.toLatestPosition()

          if (code === 1) {
            const { answer, is_end } = data

            if (this.getLastDialog().role === 'assistant') {
              // 追加回答内容
              this.getLastDialog().content += answer // TODO：打字机效果没有

              this.toLatestPosition()

              if (is_end) {
                console.log('回答完毕')
                this.waiting = false // 等到回复了，且回复完毕

                this.getLastDialog().id = new Date().getTime()

                const { completion_tokens, prompt_tokens, total_tokens } =
                  data.usage

                this.tokens = total_tokens
                // 记录tokens使用情况
                this.saveUsage(prompt_tokens, completion_tokens, total_tokens)
                // 把AI的回答保存下来给后端
                await ai_create_chat({
                  // {
                  //   "session_id":"",//会话id
                  //     "role_type":0,//0：用户提问，1：ai回答，2：模板背景
                  //     "content":"",
                  //     "is_same_problem":0//用户是否将问题修改后再提问
                  // }
                  session_id: this.session_id,
                  role_type: 1,
                  content: this.getLastDialog().content, // AI回答的完整内容
                  is_same_problem: 0,
                })

                // 更新界面的聊天列表内容
                this.getChatList(false)
              }
            } else {
              // AI 开始回答了
              this.dialogs.push({
                role: 'assistant',
                content: answer,
                time: dayjs(new Date().getTime()).fromNow(),
              })

              this.toLatestPosition()
            }

            this.repeatI = -1
          } else {
            // TODO: 出错了
            this.waiting = false
            this.toLatestPosition()
          }
        },
      }).then((controller) => {
        if (!this.controller) {
          this.controller = controller
          if (this.aborting) {
            this.abortAIAnswer()
          }
        }
      }).catch((err) => {
        console.log(err, 'fetchStream err::')
        this.waiting = false
        this.repeatI = -1

        if (this.getLastDialog().role === 'assistant') {
          if (this.getLastDialog().content === '') {
            this.dialogs.splice(this.dialogs.length - 1, 1)
          }
        }

        this.dialogs.push({
          role: 'system',
          content: err.message || JSON.stringify(err),
          time: dayjs(new Date().getTime()).fromNow(),
        })
        this.toLatestPosition()

        this.aborting = false
        this.controller = null
      })
    },
    saveUsage(a, b, c) {
      ai_save_usage({
        /**
         * {
            "session_id": "x5EgrGMN",
            "prompt_tokens":1,
            "completion_tokens":1,
            "total_tokens":2
        }*/
        session_id: this.session_id,
        prompt_tokens: a,
        completion_tokens: b,
        total_tokens: c,
      }).catch(() => {})
    },
    createSession() {
      if (this.newLoading) return

      this.newLoading = true
      return ai_create_session()
        .then(({ data, msg }) => {
          this.session_id = data.session_id
          // this.$message.success('新建对话开始')
          console.log('新建对话开始')
          // localStorage.setItem('ai_session_id', data.session_id)
          this.newLoading = false

          if (!this.ready) this.ready = true
          if (this.waiting) this.waiting = false
        })
        .catch((err) => {
          console.log(err)
          this.newLoading = false
        })
    },
    /**
     * 超过指定行数，textarea 产生自带的滚动条并不好看
     * 这边监听超出后隐藏（即往右侧偏移一点）
     */
    onResize() {
      const resizeObserver = new ResizeObserver((entries) => {
        //回调
        // console.log(this.$refs.questionRef?.offsetHeight)
        // 96 就是 四行文字的高度，字号 16，行高 1.5
        if (this.$refs.questionRef?.offsetHeight < 96) {
          this.lessThan = true
        } else {
          this.lessThan = false
        }
      })

      //监听对应的dom
      resizeObserver.observe(this.$refs.questionRef)

      this.focusRow = 5
    },
    getChatList(latest = true) {
      if (!this.session_id) return
      ai_chat_list({
        session_id: this.session_id,
      })
        .then(({ data, msg }) => {
          this.dialogs = []

          // 开头是否是模板
          if (data.chat_list.length) {
            if (data.chat_list[0].data.length) {
              if (data.chat_list[0].data[0].role_type === 2) {
                if (
                  this.myTemplate?.value === data.chat_list[0].data[0].content
                ) {
                } else {
                  let templateInfo = null
                  let subTemplateInfo = null
                  // TODO: 看看用的是什么模板
                  if (this.templateList) {
                    this.templateList.forEach((t) => {
                      t.templates.forEach((tt) => {
                        if (tt.value === data.chat_list[0].data[0].content) {
                          templateInfo = t
                          subTemplateInfo = tt
                        }
                      })
                    })
                  }
                  this.myTemplate = {
                    root: templateInfo || {}, // TODO：还不知道是什么模板类型
                    text: subTemplateInfo ? subTemplateInfo.text : '',
                    value: data.chat_list[0].data[0].content,
                    description: subTemplateInfo ? subTemplateInfo.description : '',
                  }
                }
              } else {
                // 不是以提示词开头，重置
                this.myTemplate = null
              }
            }
          }

          console.log(this.myTemplate, '是否有选择模板？')

          data.chat_list.forEach((ch, chI) => {
            let repeated = -1

            /* .filter(el => el.role_type !== 2) */
            ch.data.forEach((chh, chhI) => {
              if (chhI > 0) {
                // 和上一个提问比较，如果问题一样，应记录到版本中，而非新的对话框
                if (chh.content === ch.data[chhI - 1].content) {
                  if (repeated === -1) repeated = chhI - 1

                  if (ch.data[repeated].children) {
                    if (ch.data[repeated].versions === undefined) {
                      ch.data[repeated].versions = []
                      if (ch.data[repeated].children) {
                        ch.data[repeated].versions.push({
                          ...ch.data[repeated].children,
                          pid: ch.data[repeated].id,
                        })
                      }
                    }
                  }
                }
              }

              if (repeated === -1) {
                this.dialogs.push(chh)
              } else {
                if (chh.children) {
                  ch.data[repeated].versions.push({
                    ...chh.children,
                    pid: chh.id,
                  })
                }
              }
            })
          })

          if (!this.ready) this.ready = true

          if (
            this.dialogs.length &&
            this.dialogs[this.dialogs.length - 1].role_type === 0
          ) {
            if (this.dialogs[this.dialogs.length - 1].children) {
              // AI 已回答
              if (this.waiting) this.waiting = false
            } else {
              // AI 未回答（可能某些原因退出了对话
              this.waiting = true
              this.sendAI()
            }
          } else {
            if (this.waiting) this.waiting = false
          }

          if (latest) this.toLatestPosition()
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.ai-chat {
  height: 100%;

  &.dialog {
    .header {
      padding-right: 60px;
    }
  }

  &:not(.dialog) {
    .float-card {
      max-height: 40vh;
    }
  }
}

::v-deep .el-scrollbar__wrap {
  display: flex;
  flex-direction: column;

  .el-scrollbar__view {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.header {
  height: 76px;
  flex-shrink: 0;
  border: 0.5px solid #00c2ff26;
  background: linear-gradient(0deg, #00c2ff0d 0%, #00c2ff0d 100%), #fff;
  padding-right: 20px;
  padding-left: 20px;
  top: 0;

  .btn__icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    vertical-align: sub;
  }

  .btn__text {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
  }

  .el-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-shrink: 0;
    border-radius: 6px;

    &:hover {
      box-shadow: inset 0 0 10px 2px #00b2ff1a;
    }

    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 27.2px;
    }
  }

  .el-button--primary {
    height: 36px;
    background: #00b2ff;
    border: none;

    span {
      color: #ffffff;
    }
  }

  .el-button--default.is-plain {
    height: 40px;
    border: 1px solid #00b2ff33;
    background: #00b2ff0f;

    color: #00b2ff;

    span {
      color: #00b2ff;
      text-align: right;
    }
  }
}

.row {
  padding: $space;
}

.suggestions {
  .intro {
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background: linear-gradient(
      116deg,
      #d04bff 16.34%,
      #fe7ed2 48.79%,
      #ffb4fc 83.66%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    width: 158px + 15px + 9px;
    height: 24px;
    white-space: nowrap;

    .idea {
      width: 18px;
      height: 18px;
      margin-right: 7px;
      vertical-align: text-top;
    }

    & + .card {
      margin-top: 11px;
    }
  }

  .card {
    display: inline-flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid #6fdfff33;
    background: linear-gradient(118deg, #f6fdffcc 0%, #f5fdffcc 100%);
    max-width: 604px;

    .p {
      color: #008ab6;
      font-variant-numeric: lining-nums tabular-nums;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }

    .list {
      align-items: flex-start;
    }

    .sentence {
      display: flex;
      padding: 10px;
      align-items: flex-start;
      border-radius: 4px;
      border: 0.5px solid #00a2d633;
      background: #effbff;

      &:hover {
        box-shadow: inset 0 0 10px 2px #00a2d633;
      }

      & + .sentence {
        margin-top: 12px;
      }

      .p {
        color: #008ab6;
        font-variant-numeric: lining-nums tabular-nums;
        font-family: 'Noto Sans SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }

      .i {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        margin-top: 0.2em;
      }
    }
  }
}

.footer {
  width: 100%;
  max-width: 760px;
  /*min-height: 142px;*/
  bottom: 10px;
  background-color: white;
  margin: 0 auto;

  ::v-deep .el-scrollbar__wrap {
    /*overflow: auto hidden;*/
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 2px;
  }

  ::v-deep .el-scrollbar__bar.is-vertical {
    /*display: none;*/
    opacity: 0;
  }

  .input-box {
    width: 740px;
    min-height: 66px;
    flex-shrink: 0;
    border-radius: 8px;
    background: white;
    box-shadow: 0 2px 4px 0 #dae0e714, 0 4px 24px 0 #bfc7d03d;
    //margin-left: calc((100% - 740px) * 0.5 + 10px);
    margin-left: 10px;
    /*margin: 0 10px;*/
    margin-top: 8px;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      width: 4px;
      /*height: 66px;*/
      height: 100%;
      flex-shrink: 0;
      background: url(../../../modules/ai/assets/input-prefix.png) lightgray -66.91px -3.57px /
        2654.488% 114.849% no-repeat;
    }

    $offset: 15px;
    .el-textarea {
      //width: calc(100% + #{$offset});
      //margin-right: -#{$offset};
    }

    ::v-deep .el-textarea__inner {
      border: none;
      padding: 0;
      font-size: 16px;
      line-height: 1.5;
    }

    .input-wrapper {
      padding: 21px 15px 10px 12px;
      /*position: relative;*/

      &.focusing {
        padding-right: 3px;
      }

      &:not(.focusing) {
        .input-wrapper-inner {
          height: 1.5em;
          overflow: hidden;
          ::v-deep .el-scrollbar__wrap {
            // 实际上我们的内容是放在这个div下面的
            // height: 100%; // 渲染出来的div.el-scrollbar__wrap默认会添加height:100%的属性。我们可以设置为105%来隐藏元素水平滚动条
            /*height: 100%;*/
            /*overflow: scroll;*/
            /*overflow-x:auto;*/
          }

          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: white;
            width: $offset;
            height: 100%;
          }
        }

        .el-textarea {
          //width: calc(100% + #{$offset});
          //margin-right: -#{$offset};
        }
      }

      &.less {
        /*.el-textarea {*/
        /*  width: 100%;*/
        /*  margin-right: unset;*/
        /*}*/
      }

      .input-wrapper-inner {
        position: relative;

        /*&:after {*/
        /*  content: '';*/
        /*  display: block;*/
        /*  position: absolute;*/
        /*  bottom: 0;*/
        /*  right: 0;*/
        /*  background-color: white;*/
        /*  width: 0.5em;*/
        /*  height: 0.5em;*/
        /*}*/
      }
    }
  }

  .shortcut-list {
    flex-wrap: nowrap;
    padding: 10px 20px 0;

    .shortcut-item {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      border-radius: 8px;

      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;

      /*&:hover {*/
      /*  transform: scale(1.05);*/
      /*}*/

      &.article_edit {
        border: 1px solid #ffe8fd;
        background: #fff7ff;
        color: #4e184b;

        &:hover {
          box-shadow: inset 0 0 10px 2px #ffe8fd;
        }
      }

      &.event_plan {
        border: 1px solid #ffece8;
        background: #fffaf8;
        color: #38150e;

        &:hover {
          box-shadow: inset 0 0 10px 2px #ffece8;
        }
      }

      &.daily_operate {
        border: 1px solid #cdffdb;
        background: #f5fffa;
        color: #105055;

        &:hover {
          box-shadow: inset 0 0 10px 2px #cdffdb;
        }
      }

      & + .shortcut-item {
        margin-left: 12px;
      }

      .icon {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        margin-right: 6px;
      }

      .el-icon-arrow-right {
        /*margin-left: 4px;*/
        font-size: inherit;
        margin-left: 4px;
      }
    }
  }

  .send {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 27px;
    //background: url(<path-to-image>) lightgray -64px -32.46px / 500% 302.863% no-repeat, #D9D9D9;
    position: absolute;
    right: 17px;
    bottom: 17px;

    &.gray {
      filter: grayscale(1);
    }

    .el-icon-top {
      color: white;
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.header,
.footer {
  position: sticky;
}

.dialog-item + .dialog-item {
  margin-top: 12px;
}

.float-card {
  width: calc(100% - 20px * 2);
  /*height: 480px;*/
  height: calc(100vh - 220px - 76px - 20px * 2);
  max-height: 60vh;
  flex-shrink: 0;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 16px 0 #0000001f;
  position: absolute;
  /*top: 92px;*/
  top: 16px;
  right: 20px;
  z-index: 2;

  .float-header {
    color: #1a1a1a;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    padding: 20px;
  }

  .scroll-content {
    padding: 0 12px;
  }

  .float-footer {
    display: flex;
    width: 100%;
    height: 84px;
    padding: 24px;
    /*justify-content: flex-end;*/
    align-items: center;
    flex-shrink: 0;
    border-top: 1px solid #0000001a;
    background-color: white;
  }

  .history-item {
    //width: 696px;
    height: 85px;
    flex-shrink: 0;
    border-radius: 8px;
    padding: 13px 16px 16px;
    position: relative;

    &.check-mode {
      padding-left: 40px;
    }

    & + .history-item {
      margin-top: 8px;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    .full-check {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-left: 16px;
    }

    .title {
      color: #1a1a1a;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      width: 70%;
      @include nowrap();
    }

    .subtitle {
      width: 664px;
      color: #808080;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      margin-top: 11px;
      @include nowrap();
    }

    .del-btn {
      position: absolute;
      right: 16px;
      top: 4px;
      font-size: 14px;
    }
  }
}

.template-screen {
  line-height: 1.5;
  height: 100vh;
  position: relative;

  .bg {
    position: absolute;
    width: 100%;
    top: 0;
  }

  .template-content {
    /*width: 760px;*/
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 70px;
  }

  .article_edit {
    background: linear-gradient(0deg, #ff8ade08 28.33%, #e962ff1f 100%), #fff;

    .template-grid-item.checked {
    }
  }

  .event_plan {
    background: linear-gradient(180deg, #ff65651f 0%, #ffd17708 103.16%), #fff;

    .template-grid .template-grid-item.checked {
      border-color: #ff6565;
    }
  }

  .daily_operate {
    background: linear-gradient(180deg, #68f6ff4d 0%, #cbfffc33 100%), #fff;

    .template-grid .template-grid-item.checked {
      border-color: #68f6ff;
    }
  }

  .template-header {
    .header-right {
      padding-top: 4px;
    }
  }

  .title {
    color: #1a1a1a;
    font-size: 24px;
    font-weight: 500;
  }

  .desc {
    width: 289px;
    color: #808080;
    font-size: 14px;
    margin-top: 4px;
  }

  .template-subtitle {
    margin-top: 32px;
    margin-bottom: 16px;

    .subtitle {
      color: #1a1a1a;
      font-size: 14px;
      font-style: normal;
    }
  }

  .template-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    .template-grid-item {
      /*width: 213px;*/
      /*height: 138px;*/
      flex-shrink: 0;
      border-radius: 4px;
      background-color: white;
      padding: 16px;
      position: relative;
      border: 1px solid white;

      &.checked {
        border-color: #ff8ade;
      }

      .grid-icon {
        width: 32px;
        height: 32px;
        margin-bottom: 8px;
      }

      .grid-title {
        color: #1a1a1a;
        font-size: 14px;
      }

      .grid-desc {
        color: #b3b3b3;
        font-size: 12px;
        line-height: 20px;
        margin-top: 2px;
        @include clamp(2);
      }

      .checkbox {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }

  .footer {
    width: 760px;
    height: 100px;
    background: #ffffff;
    padding-top: 24px;
    padding-right: 40px;
    padding-left: 40px;
    min-height: 0;
  }
}

.my-template {
  display: flex;
  padding: 16px 20px;
  /*flex-direction: column;*/
  /*align-items: flex-start;*/
  border-radius: 8px;
  border: 1px solid black;
  background-color: white;
  color: black;
  margin: 20px;

  &:not(.full) {
    .my-template-desc {
      @include clamp(2);
    }
  }

  &.full {
    .my-template-desc {
      white-space: pre-wrap;
    }
  }

  &.article_edit {
    background: #e649ff0a;
    border-color: #e649ff33;
  }

  &.event_plan {
    border-color: #ff656533;
    background: linear-gradient(0deg, #ff65650a 0%, #ff65650a 100%), #fff;
  }

  &.daily_operate {
    border-color: #31bdfe33;
    background: linear-gradient(0deg, #31bdfe14 0%, #31bdfe14 100%), #fff;
  }

  .my-template-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .my-template-title {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }

  .my-template-desc {
    color: #808080;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 8px;
  }
}

.footer-keywords {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;

  .keyword {
    display: inline-flex;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #eff2f6;
    background: #f8f9fb;
    margin-right: 10px;

    ._text {
      color: #808080;
      font-variant-numeric: lining-nums tabular-nums;
      font-family: 'Noto Sans SC';
      font-size: 13px;
      line-height: 20px;
    }
  }
}

.wh-full {
  width: 100%;
  height: 100%;
}

.w-full {
  width: 100%;
}

.welcome {
  width: 132px;
  height: 105px;
  overflow: visible;
  position: relative;
  padding-top: 28px;
  box-sizing: content-box;
  margin-bottom: 57px;

  .welcome-ball {
    width: 100%;
    height: 84px;
    background: url(../../../modules/ai/assets/welcome.png);
    background-size: 100% auto;
    background-position: top left;
  }
}

.welcome-shadow {
  width: 132px;
  height: 7px;
  flex-shrink: 0;
  background: linear-gradient(
    90deg,
    #15cef500 0%,
    #15cef5 46.5%,
    #15cef500 100%
  );
  filter: blur(7px);
  position: absolute;
  bottom: 0;
}
</style>
