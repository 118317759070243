<template>
  <div :class="{ me }" :id="`id-${id}`" class="ai-chat-item flex">
    <div class="main flex-col">
      <time :datatime="create_time" v-if="create_time">{{
        create_time | localDate
      }}</time>
      <time :datatime="time" v-else-if="time">{{ time }}</time>
      <div
        @mouseenter="enterContent"
        @mouseleave="leaveContent"
        class="block flex-col"
        :class="{completed: !!id}"
      >
        <template v-if="versions && versions.length && versions[versionI] && !me">
          <div class="block-head flex" v-if="isLast">
            <div class="version-index">版本{{ versionI + 1 }}：</div>
<!--            {{pid}}#{{id}}#{{versions[versionI].id}}-->
          </div>
          <vue-markdown :source="versions[versionI].content" is-preview></vue-markdown>
        </template>
        <template v-else>
          <p class="content raw" v-if="me">{{ content }}</p>
          <div class="content overflow-x" v-else>
            <vue-markdown :source="myContent" is-preview></vue-markdown>
          </div>
        </template>

        <!-- 没有 id 时，就是前端自己构造的临时数据 -->
        <div class="controls static flex-between" v-if="!me && id">
          <div @click="clickCopy(content)" class="copy cursor-pointer">
<!--            <i class="el-icon-document-copy"></i>-->
            <img :src="require('@/modules/ai/assets/copy.png')" class="el-icon-document-copy"/>
            <span class="__text">复制</span>
          </div>

          <div class="flex-align-center">
            <!-- 让 AI 重新回答 -->
            <div
              @click="clickRepeat(content)"
              class="copy cursor-pointer"
              v-if="isLast"
            >
<!--              <i class="el-icon-refresh"></i>-->
              <img :src="require('@/modules/ai/assets/refresh.png')" class="el-icon-refresh"></img>
            </div>

            <div
              class="version-switch flex-align-center"
              v-if="versions && versions.length && isLast"
            >
              <i
                @click="prevVersion('prev')"
                class="el-icon-arrow-left cursor-pointer"
                v-if="versionI > 0"
              ></i>
              <i
                class="el-icon-arrow-left disabled"
                v-else-if="versionI === 0"
              ></i>
              <span class="indicator-text"
                >{{ versionI + 1 }} / {{ versions.length }}</span
              >
              <i
                @click="prevVersion('next')"
                class="el-icon-arrow-right cursor-pointer"
                v-if="versionI < versions.length - 1"
              ></i>
              <i
                class="el-icon-arrow-right disabled"
                v-else-if="versionI === versions.length - 1"
              ></i>
            </div>
          </div>
        </div>
        <div
          :class="[entered && me ? 'hover' : '']"
          class="controls flex"
          v-else
        >
          <div class="copy mini flex-align-center cursor-pointer">
<!--            <i @click="clickCopy(content)" class="el-icon-document-copy"></i>-->
            <img :src="require('@/modules/ai/assets/copy.png')" @click="clickCopy(content)" class="el-icon-document-copy"/>
            <!-- 最后一次用户的提问可以编辑 -->
            <template v-if="isLast">
              <el-divider direction="vertical"></el-divider>
<!--              <i @click="clickEdit()" class="el-icon-edit"></i>-->
              <img :src="require('@/modules/ai/assets/edit-my-question.png')" @click="clickEdit()" class="el-icon-edit"/>
            </template>
          </div>
        </div>
      </div>
    </div>
    <el-avatar v-if="me && headerData.admin_user.avatar"
      :size="32"
      :src="fixImageUrl(headerData.admin_user.avatar, 300)"
      class="avatar"
    ></el-avatar>
    <el-avatar :size="32"
      v-else-if="me"
      :src="defaultAvatar"
      class="avatar"
    ></el-avatar>
    <el-avatar :size="32"
      :src="require('@/modules/ai/assets/ai-avatar.png')"
      class="avatar"
      v-else
    ></el-avatar>
  </div>
</template>

<script>
// 1. 在 Vue 组件中引入插件：在组件中引入 vue-markdown 插件，代码如下：
import VueMarkdown from 'vue-markdown'

import { copyText } from '@/base/utils/tool'

import dayjs from 'dayjs'
import {ai_update_chat} from '../api/ai-chat'
// 启用中文
require('dayjs/locale/zh-cn')
dayjs.locale('zh-cn')

// 相对时间
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)
const relativeTimeConfig = {
  future: '%s内',
  past: '%s前',
  s: '刚刚',
  m: '1 分钟',
  mm: '%d 分钟',
  h: '1 小时',
  hh: '%d 小时',
  d: '1 天',
  dd: '%d 天',
  M: '1 个月',
  MM: '%d 个月',
  y: '1 年',
  yy: '%d 年',
}
dayjs.updateLocale('zh-cn', {
  relativeTime: relativeTimeConfig,
})

export default {
  components: {
    VueMarkdown,
  },
  props: {
    // 是不是我发出的
    me: {
      type: Boolean,
      default: false,
    },
    content: String,
    time: String,
    create_time: String,
    // 是否最后一次提问
    isLast: Boolean,
    id: [String, Number],
    pid: [String, Number],
    versions: Array,
    is_usage: Number
  },
  data() {
    return {
      entered: false,

      versionI: -1,

      myContent: '',

      defaultAvatar:
          window.serverConfig.VUE_APP_ADMINURL +
          '/cyc/images/wxapps/icons/avatar1.png', // 默认头像
    }
  },
  computed: {
    // header数据
    headerData() {
      return this.$store.getters['header/headerData']
    },
  },
  watch: {
    versionI(newValue, oldValue) {
      if (oldValue === -1) return
      this.toLatestPosition()
    },
    content: {
      handler(val, oldVal) {
        let that = this

        function start() {
          if (that.timer) {
            clearInterval(that.timer)
            that.timer = null
            that.myContent = oldVal || ''
          }

          let str = val.replace(oldVal, '') // 追加的内容
          let str_ = ''
          let i = 0

          let timer = setInterval(() => {
            if (str_.length < str.length) {
              str_ += str[i++]
              that.myContent = (oldVal || '') + str_ + '_' // 打印时加光标
            } else {
              clearInterval(timer)
              that.myContent = val
            }
          }, 100)
          that.timer = timer
        }

        start()
      },
      immediate: false,
    },
  },
  filters: {
    localDate(value) {
      const dayjsText = dayjs(value || new Date()).fromNow()
      return dayjsText === '刚刚前' ? '刚刚' : dayjsText
    },
  },
  created() {
    this.timer = null
    this.myContent = this.content
    this.versionI = !this.versions?.length ? 0 : this.versions.findIndex(v => v.is_usage === 1)
  },
  methods: {
    prevVersion(action = '') {
      if (action === 'prev') {
        this.versionI--
      } else if (action === 'next') {
        this.versionI++
      }
      this.versions.forEach((v, vI) => {
        // 更新是否使用这个答案作为上下文
        ai_update_chat({
          chat_id: this.versions[vI].id,
          is_usage: vI === this.versionI ? 1 : 0
        }).catch(() => {})
        // 更新是否使用这个提问作为上下文
        ai_update_chat({
          chat_id: this.versions[vI].pid,
          is_usage: vI === this.versionI ? 1 : 0
        }).catch(() => {})
      })
    },
    clickCopy(text) {
      copyText(text, true).then((valid) => {
        this.$message.success('复制成功')
      })
    },
    clickEdit() {
      this.$emit('edit', this.content)
    },
    clickRepeat() {
      this.$emit('repeat', this.content)
    },
    leaveContent() {
      this.entered = false
    },
    enterContent() {
      this.entered = true
    },
    toLatestPosition() {
      setTimeout(() => {
        this.$nextTick(() => {
          let end = document.getElementById('id-' + this.pid)
          end.scrollIntoView({ block: 'nearest' })
        })
      }, 100)
    },
  },
}
</script>

<style lang="scss">
.ai-chat-item {
  flex-direction: row-reverse;
  justify-content: flex-end;

  &.me {
    flex-direction: row;
    /*justify-content: flex-end;*/

    .main {
      align-items: flex-end;
    }

    .block {
      background: #0085ff;
      overflow: visible;

      .content.raw, .content {
        color: #ffffff;
      }
    }
  }

  &:not(.me) {
    .block.completed {
      min-width: 500px;
    }
  }

  .block {
    display: inline-flex;
    padding: 16px;
    justify-content: center;
    gap: 10px;
    border-radius: 8px;
    max-width: 500px;
    background-color: #f8f9fb;
    position: relative;
    line-height: 1.73;
    overflow: hidden;

    .content.raw {
      font-size: 15px;
      /*font-weight: 500;*/
      line-height: 25.5px;
      color: #1a1a1a;
      text-align: justify;
      white-space: pre-wrap;
    }
  }

  time {
    color: #b3b3b3;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 4px;
  }

  .main {
    margin: 0 7px;
  }

  .avatar {
    flex-shrink: 0;
  }

  .controls {
    width: 100%;
    display: none;

    &.hover {
      position: absolute;
      left: 15px;
      bottom: -13px;
      display: flex;

      .copy {
        /*height: ;*/
      }
    }

    &.static {
      display: flex;
    }
  }

  .copy {
    display: flex;
    height: 35px;
    padding: 6px 10px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 0.1px solid #00c2ff80;
    background: linear-gradient(0deg, #00c2ff0a 0%, #00c2ff0a 100%), #f8f9fb;
    color: #00a2d6;

    .__text {
      color: #00a2d6;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }

    &.mini {
      display: inline-flex;
      padding: 5px 7px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #00a2d633;
      background-color: white;
      font-size: 12px;
      min-width: 30px;
      height: 26px;

      .el-divider {
        color: #00a2d633;
        margin: 0 4px;
      }
    }

    .el-icon-document-copy, .el-icon-edit, .el-icon-refresh {
      width: 16px;
      height: 16px;
    }
  }

  .version-switch {
    color: #00a2d6;
    margin-left: 12px;

    .cursor-pointer {
      /*color: */
    }

    .indicator-text {
      margin: 0 8px;
      display: inline-block;
    }

    .disabled {
      color: #8080802f;
      cursor: not-allowed;
    }
  }

  .block-head {
    width: 100%;
    justify-content: flex-start;
  }

  .version-index {
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #e063ff1a;

    color: #e063ff;
    font-family: 'Alibaba PuHuiTi';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
