import api from '@/base/utils/request'

export const ai_session_list = (data) => {
  return api({url:'/admin/ai/chat/sessionList', method: 'post', data})
}

export const ai_create_session = (data) => {
  return api({ url: '/admin/ai/chat/createSession', method: 'post', data })
}

export const ai_del_session = (data) => {
  return api({url:'/admin/ai/chat/delSession', method: 'post', data, notCancel: true})
}

export const ai_create_chat = (data) => {
  return api({ url: '/admin/ai/chat/createChat', method: 'post', data })
}

export const ai_chat_list = (data) => {
  return api({url:'/admin/ai/chat/sessionDetail', method: 'post', data})
}

export const ai_save_usage = (data) => {
  return api({url:'/admin/ai/chat/saveUsage', method: 'post', data})
}

export const ai_template_list = (data) => {
  return api({url:'/admin/ai/chat/templatesList', method: 'post', data})
}

export const ai_update_chat = (data) => {
  return api({url:'/admin/ai/chat/updateChat', method: 'post', data, notCancel: true})
}