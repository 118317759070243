<template>
  <div class="page flex-1">
    <div class="content-absolute">
      <ai-chat role="view"></ai-chat>
    </div>
  </div>
</template>

<script>
import AiChat from '../components/AIChat'
export default {
  components: {AiChat},
  provide() {
    return {
      getVisible: () => true
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.page {
  position: relative;

  .content-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>